var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-card',[_c('validation-observer',{ref:"simpleRules"},[_c('b-form',[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.$t("Preventive Maintenance Plan")))]),_c('hr',{staticClass:"mb-2"}),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Name'),"label-for":"name"}},[_c('validation-provider',{attrs:{"name":"name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"name"},model:{value:(_vm.maintenanceData.name),callback:function ($$v) {_vm.$set(_vm.maintenanceData, "name", $$v)},expression:"maintenanceData.name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Note'),"label-for":"note"}},[_c('validation-provider',{attrs:{"name":"note","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"note"},model:{value:(_vm.maintenanceData.note),callback:function ($$v) {_vm.$set(_vm.maintenanceData, "note", $$v)},expression:"maintenanceData.note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"d-flex align-items-center justify-content-start"},[_c('h4',{staticClass:"d-inline-block mr-1"},[_vm._v(_vm._s(_vm.$t("Steps")))]),_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],staticClass:"mr-2 mb-0",attrs:{"button":"","rounded":"sm","variant":"primary","size":"24px","title":"Adicionar"},on:{"click":function($event){return _vm.addPreventiveMaintenances()}}},[_c('feather-icon',{attrs:{"icon":"PlusCircleIcon"}})],1)],1)])],1),_c('hr'),_vm._l((_vm.maintenanceData.preventiveMaintenances),function(preventiveMaintenances,index){return (_vm.maintenanceData.preventiveMaintenances.length > 0)?_c('div',{key:index},[_c('div',[_c('b-row',{staticClass:"mb-0 mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('div',{staticClass:"float-right"},[_c('b-avatar',{directives:[{name:"b-tooltip",rawName:"v-b-tooltip.hover",modifiers:{"hover":true}}],attrs:{"button":"","rounded":"sm","variant":"danger","size":"24px","title":"Remover"},on:{"click":function($event){return _vm.removePreventiveMaintenances(index)}}},[_c('feather-icon',{attrs:{"icon":"Trash2Icon"}})],1)],1)])],1),_c('div',[_c('b-row',{staticClass:"mt-2"},[_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Description'),"label-for":"description"}},[_c('validation-provider',{attrs:{"name":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"description"},model:{value:(_vm.maintenanceData.preventiveMaintenances[index].name),callback:function ($$v) {_vm.$set(_vm.maintenanceData.preventiveMaintenances[index], "name", $$v)},expression:"maintenanceData.preventiveMaintenances[index].name"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Odometer'),"label-for":"odometer"}},[_c('validation-provider',{attrs:{"name":"odometer","rules":"regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"odometer"},model:{value:(_vm.maintenanceData.preventiveMaintenances[index].odometer),callback:function ($$v) {_vm.$set(_vm.maintenanceData.preventiveMaintenances[index], "odometer", $$v)},expression:"maintenanceData.preventiveMaintenances[index].odometer"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1),_c('b-col',{attrs:{"cols":"12","md":"4"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Hourmeter'),"label-for":"hourmeter"}},[_c('validation-provider',{attrs:{"name":"hourmeter","rules":"regex:^([0-9]+)$"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-input',{attrs:{"id":"hourmeter"},model:{value:(_vm.maintenanceData.preventiveMaintenances[index].hourmeter),callback:function ($$v) {_vm.$set(_vm.maintenanceData.preventiveMaintenances[index], "hourmeter", $$v)},expression:"maintenanceData.preventiveMaintenances[index].hourmeter"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1),_c('b-row',[_c('b-col',{attrs:{"cols":"12","md":"12"}},[_c('b-form-group',{attrs:{"label":_vm.$t('Note'),"label-for":"note-information"}},[_c('validation-provider',{attrs:{"name":"note-information","rules":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-form-textarea',{attrs:{"id":"note-information"},model:{value:(_vm.maintenanceData.preventiveMaintenances[index].note),callback:function ($$v) {_vm.$set(_vm.maintenanceData.preventiveMaintenances[index], "note", $$v)},expression:"maintenanceData.preventiveMaintenances[index].note"}}),_c('small',{staticClass:"text-danger"},[_vm._v(_vm._s(errors[0]))])]}}],null,true)})],1)],1)],1)],1),_c('hr')],1)]):_vm._e()}),_c('b-row',[_c('b-col',{attrs:{"cols":"12"}},[_c('b-button',{attrs:{"variant":"primary","type":"submit"},on:{"click":function($event){$event.preventDefault();return _vm.validationForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("Save"))+" ")])],1)],1)],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }