<template>
    <b-card>
        <validation-observer ref="simpleRules">
            <b-form>
                <!-- Section Informations -->
                <h4 class="mb-0">{{ $t("Preventive Maintenance Plan") }}</h4>
                <hr class="mb-2" />
                <b-row>
                    <!-- Field:  Name -->
                    <b-col cols="12" md="12">
                        <b-form-group :label="$t('Name')" label-for="name">
                            <validation-provider #default="{ errors }" name="name" rules="required">
                                <b-form-input id="name" v-model="maintenanceData.name" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>

                    <!-- Field:  Code -->
                    <!-- <b-col cols="12" md="6">
                        <b-form-group :label="$t('Code')" label-for="code">
                            <validation-provider #default="{ errors }" name="code" rules="">
                                <b-form-input id="additionalCode" v-model="maintenanceData.additionalCode" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col> -->
                </b-row>

                <b-row>
                    <!-- Field:  Note -->
                    <b-col cols="12" md="12">
                        <b-form-group :label="$t('Note')" label-for="note">
                            <validation-provider #default="{ errors }" name="note" rules="">
                                <b-form-textarea id="note" v-model="maintenanceData.note" />
                                <small class="text-danger">{{ errors[0] }}</small>
                            </validation-provider>
                        </b-form-group>
                    </b-col>
                </b-row>

                <b-row>
                     
                    <b-col cols="12" md="12">
                        <div class="d-flex align-items-center justify-content-start">
                            <h4 class="d-inline-block mr-1" >{{ $t("Steps") }}</h4> 
                            <b-avatar class="mr-2 mb-0" @click="addPreventiveMaintenances()" button rounded="sm" variant="primary" size="24px" v-b-tooltip.hover title="Adicionar">
                                <feather-icon icon="PlusCircleIcon" />
                            </b-avatar>
                        </div>
                    </b-col>
                </b-row>

                
                <hr/>

                <div  v-if="maintenanceData.preventiveMaintenances.length > 0" v-for="(preventiveMaintenances, index) in maintenanceData.preventiveMaintenances" :key="index">
                    <div >
                        
                        <b-row  class="mb-0 mt-2">
                            <b-col cols="12" md="12">
                                <div class="float-right">
                                    <b-avatar @click="removePreventiveMaintenances(index)" button rounded="sm" variant="danger" size="24px" v-b-tooltip.hover title="Remover">
                                        <feather-icon icon="Trash2Icon" />
                                    </b-avatar>
                                </div>
                            </b-col>
                        </b-row>
                        <div >
                            <b-row class="mt-2" >
                            <!-- Field:  Description -->
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('Description')" label-for="description">
                                    <validation-provider #default="{ errors }" name="description" rules="">
                                        <b-form-input id="description" v-model="maintenanceData.preventiveMaintenances[index].name" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                            <!-- Field:  odometer -->
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('Odometer')" label-for="odometer">
                                    <validation-provider #default="{ errors }" name="odometer" rules="regex:^([0-9]+)$">
                                        <b-form-input id="odometer" v-model="maintenanceData.preventiveMaintenances[index].odometer" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>

                        <!-- Field:  hourmeter -->
                            <b-col cols="12" md="4">
                                <b-form-group :label="$t('Hourmeter')" label-for="hourmeter">
                                    <validation-provider #default="{ errors }" name="hourmeter" rules="regex:^([0-9]+)$">
                                        <b-form-input id="hourmeter" v-model="maintenanceData.preventiveMaintenances[index].hourmeter" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        <b-row>
                            <!-- Field:  Note -->
                            <b-col cols="12" md="12">
                                <b-form-group :label="$t('Note')" label-for="note-information">
                                    <validation-provider #default="{ errors }" name="note-information" rules="">
                                        <b-form-textarea id="note-information" v-model="maintenanceData.preventiveMaintenances[index].note" />
                                        <small class="text-danger">{{ errors[0] }}</small>
                                    </validation-provider>
                                </b-form-group>
                            </b-col>
                        </b-row>

                        </div>
                        <hr/>
                    </div>
                </div>

                <b-row>
                    <b-col cols="12">
                        <b-button variant="primary" type="submit" @click.prevent="validationForm">
                            {{ $t("Save") }}
                        </b-button>
                    </b-col>
                </b-row>
            </b-form>
        </validation-observer>
    </b-card>
</template>

<script>

import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";
import {
    BFormInput,
    BFormGroup,
    BForm,
    BRow,
    BCol,
    BButton,
    BCardText,
    BFormTextarea,
    BNavItemDropdown,
    BDropdownItem,
    BImg,
    BAvatar
} from "bootstrap-vue";
import { ref, onUnmounted } from "@vue/composition-api";
import router from "@/router";
import store from "@/store";
import managementStoreModule from "../managementStoreModule";

import Cleave from "vue-cleave-component";
import "cleave.js/dist/addons/cleave-phone.br";




import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
    required,

} from "@validations";

export default {
    components: {
        BTab,
        BTabs,
        BCard,
        BAlert,
        BLink,
        BAvatar,
        ValidationProvider,
        ValidationObserver,
        BFormInput,
        BFormGroup,
        BForm,
        BRow,
        BCol,
        BButton,
        BCardText,
        BFormTextarea,
        BNavItemDropdown,
        BDropdownItem,
        BImg,

        Cleave,

    },
    data() {
        return {
            myId: router.currentRoute.params.id,
            required,
    

            optMaskValue: {
                numeral: true,
                numeralDecimalMark: ",",
                numeralDecimalScale: 2,
                delimiter: "",
                numeralIntegerScale: 5,
            },

            maintenanceData: {
                id: '',
                name: '',
                additionalCode: '',
                note: '',
                preventiveMaintenances: []
            }
        };
    },
    created() {
        if (router.currentRoute.params.id != undefined && router.currentRoute.params.id != null){
            this.fetchMaintenancePlan()
        }
    },
    methods: {

        removePreventiveMaintenances(index){
            if (index >= 0 && index < this.maintenanceData.preventiveMaintenances.length) {
                this.maintenanceData.preventiveMaintenances.splice(index, 1);
            }
        },

        addPreventiveMaintenances(){
            var newPreventiveMaintenances = {
                name:'',
                note:'',
                odometer:'',
                hourmeter:''
            }
            this.maintenanceData.preventiveMaintenances.push(newPreventiveMaintenances)
        },

        fetchMaintenancePlan(){
            store
                .dispatch("app-management/fetchMaintenancePlan", {
                    //id: router.currentRoute.params.id
                    id: router.currentRoute.params.id,
                })
                .then((response) => {
                    this.maintenanceData = response.data;

                    
                    
                })
                .catch((e) => {
                    console.log(e);
                });
        },

        validationForm() {
            this.$refs.simpleRules.validate().then((success) => {
                if (success) {
                    

                    if (this.maintenanceData.id == "") {
                        store
                            .dispatch("app-management/addMaintenancePlan", {
                                maintenanceData: this.maintenanceData,
                            })
                            .then((response) => {
                                //alert('Error 404')
                                this.msgDialog(this.$t('Successful registration'), "success");

                                router.push({
                                    name: "list-maintenance-plan",
                                });
                            })
                            .catch((error) => {
                                this.msgDialog(this.$t('Failed to save'), "error");
                                // TODO: FAZER MSG ESPESSIFICA PARA CADAS ERRO DA API
                            });
                    } else {
                        store
                            .dispatch("app-management/updateMaintenancePlan", {
                                maintenanceData: this.maintenanceData,
                            })
                            .then((response) => {
                                //alert('Error 404')
                                this.msgDialog(this.$t('Successful update'), "success");
                                this.fetchMaintenancePlan()
                                //this.clearForm();
                            })
                            .catch((error) => {
                                this.msgDialog( this.$t('FAILURE to update'), "error");
                            });
                    }
                }
            });
        },

        msgDialog(msg, icon) {
            this.$swal({
                position: "top-end",
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: "btn btn-primary",
                },

                buttonsStyling: false,
            });
        },

        
    },
    setup() {

        const MANAGEMENT_APP_STORE_MODULE_NAME = "app-management";

        // Register module
        if (!store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME))
            store.registerModule(
                MANAGEMENT_APP_STORE_MODULE_NAME,
                managementStoreModule
            );

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(MANAGEMENT_APP_STORE_MODULE_NAME))
                store.unregisterModule(MANAGEMENT_APP_STORE_MODULE_NAME);
        });

        

        return {
   
        };
    },
};
</script>


<style>
.title-head {
    margin-bottom: 32px;
}
</style>
